<template>
  <div class="container">
    <TitleComponent class="primary--text text-uppercase text-center" title="List of Completed Projects"/>
    <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="15"
    class="elevation-1"
    >
    </v-data-table>  
    
  </div>
</template>

<script>
import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent';
//import projectData from "@/json/projects.json";
export default {
  name : 'CompletedProjects',
  components : {
    TitleComponent
  },
  data() {
    return {
      //projects : projectData,
      items:[],
      headers:  [ {  text : 'No',  value:'rownumber', stickyColumn: true, },
                  {  text: 'PROJECT NAME',  align: 'start', value: 'Name'  },
                  { text: 'LOCATION', value: 'Location' },
                ], 
    }
  },
  mounted(){
    this.getAllRecords();
  },
  methods:{
    getAllRecords:function(){
      axios.get( this.$api_url+`/getAllRecords/projects/where STATUS='COMPLETED'/ID asc`,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              this.items = response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },
  },
}


</script>

<style lang="">
  
</style>